import http from '../http';

const userApi = {
	auth(data) {
		return http.post('/user/auth', data);
	},
	forgotPassword(data) {
		return http.post('/user/forgot-password', data);
	},
	resetPassword(data) {
		return http.post('/user/reset-password', data);
	},
}

const contactApi = {
	contact(data) {
		return http.post('/contact', data);
	}
}

const recaptchaApi = {
	validateRecaptcha(token) {
		return http.post('/validate-recaptcha', {token: token});
	}
}

const newsletterApi = {
	subscribe(data) {
		return http.post('/newsletter-subscribe', data);
	}
}

export {
	userApi,
	contactApi,
	recaptchaApi,
	newsletterApi
}