import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import "zurb-twentytwenty/css/foundation.css";
import "zurb-twentytwenty/css/twentytwenty.css";
import "zurb-twentytwenty/css/twentytwenty-no-compass.css";
import '../sass/sass.scss';
import './functions.js';

import Vue from 'vue';

Vue.prototype.$app = app;
Vue.config.productionTip = false;

// main
Vue.component('login', require('./components/login').default);
Vue.component('forgot-password', require('./components/forgot-password').default);
Vue.component('reset-password', require('./components/reset-password').default);
Vue.component('finance-calculator', require('./components/finance-calculator').default);
Vue.component('invisalign-finance-calculator', require('./components/invisalign-finance-calculator').default);
Vue.component('newsletter', require('./components/newsletter').default);

new Vue({
	el: '#app',
});